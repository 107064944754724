<template>
    <v-container fluid>
        <div class="pa-4">
            <h1>TERMINOS Y CONDICIONES FALCON SIGNAL</h1>
            <br>
            <h2>PRIMERO: INFORMACIÓN.</h2>
            
            <p>FESTY APP S.A.S es una sociedad constituida conforme a las leyes colombianas, identificada con NIT: 901.348.823-8, que en estos TERMINOS Y CONDICIONES actúa como OPERADOR TECNOLÓGICO DE FALCON SIGNAL.</p>
            
            <h2>SEGUNDO: NATURALEZA JURÍDICA.</h2>
            <p>Los presentes términos y condiciones de uso regulan la relación contractual de carácter comercial de los USUARIOS registrados en la PLATAFORMA DE MARKETING DIGITAL, WWW.FALCONSIGNAL.CO</p>

            <h2>TERCERO: DEFINICIONES</h2>
            <ul>
                <li>
                    Términos y condiciones: Los términos y condiciones ("Términos y condiciones") son un conjunto de términos legales definidos por el propietario de una plataforma tecnológica, donde se rigen las actividades de los usuarios y sus relaciones con terceros, usuarios finales o terceros. 
                </li>
                <li>
                    Aviso de Privacidad: Comunicación verbal o escrita generada, dirigida al Titular de dichos datos, mediante la cual se le informa acerca de la existencia de TERMINOS Y CONDICIONES que le serán aplicables, la forma de acceder a las mismas y las finalidades del tratamiento que se pretende dar a los datos personales.
                </li>
                <li>
                    Autorización: Consentimiento previo, expreso e informado del titular de los datos personales para llevar a cabo el tratamiento de dichos datos.
                </li>
                <li>
                    Bases de Datos: Conjunto organizado de datos personales que sea objeto de Tratamiento.
                </li>
                <li>
                    Dato Personal: Cualquier información vinculada o que pueda asociarse a una o a varias personas naturales determinadas o determinables.
                </li>
                <li>
                    Dato Público: Es el dato que no sea semiprivado, privado o sensible. Son considerados datos públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión u oficio y a su calidad de comerciante o de servidor público.
                </li>
            </ul>

            <br>
            <h2>CUARTO: OBJETO.</h2>

            <p>Los presentes Términos y Condiciones constituyen el acuerdo completo en la actuación entre USUARIOS, y rige el uso de la PLATAFORMA, sustituyendo acuerdos relacionados, esta plataforma tiene como objeto disponer del espacio digital donde se establecen las estrategias de marketing digital SMS y LLAMADAS AUTOMATIZADAS de los USUARIOS con sus clientes o receptores finales de la información. El uso de la base de datos por parte de los USUARIOS constituye su responsabilidad, no obstante, el OPERADOR, se reserve el derecho a revisar por solicitudes expresas de usuarios o de la autoridad competente, el uso de estas. </p>

            <p>Además, los Términos y Condiciones regulan la autorización de uso de la PLATAFORMA, para que los USUARIOS al ingresar y/o utilizar la PLATAFORMA virtual, sus canales de atención y módulos, que estén a la fecha y posteriores</p>

            <p>El OPERADOR puede modificar estos Términos y Condiciones o la Política de Privacidad cuando sea necesario. Si el OPERADOR realiza cambios sustanciales a los Términos y Condiciones, se le notificará los USUARIOS a través de sus servicios, correo electrónico, SMS, u otro medio disponible que hayan dispuesto las partes. </p>
            
            <p>Cualquier pregunta relacionada con los Términos y Condiciones debe enviarse al correo electrónico: contacto@falconsignal.co</p>

            <h2>QUINTO. SEGURIDAD DE LA INFORMACIÓN Y LIMITACIÓN DE LAS RESPONSABILIDADES</h2>
            <p>La seguridad de la información es fundamental para la protección de los activos que la contienen, de cualquier pérdida de confidencialidad, integridad o disponibilidad, tanto accidental como intencionada, teniendo en cuenta la normatividad vigente aplicable, se dispondrá de las medidas técnicas necesarios para tal fin. Sin embargo, El uso de la PLATAFORMA es bajo el propio riesgo del USUARIO, quien asume toda la responsabilidad y riesgo de pérdida derivada de su uso, incluyendo, sin limitación, la pérdida de servicios o de información. No seremos responsables por daños causados de manera directa o indirecta, incidental, consecuente o punitiva, o de cualquier otro daño a propios o terceros generados por la información derivada de la cuenta del USUARIO, ya sea en el ejercicio de la acción de un contrato, cláusula, o de cualquier otra forma, con relación a, o como resultado, del uso de LA PLATAFORMA, incluso si se tenía conocimiento respecto de la posibilidad de causar tales daños.
            Toda la información relacionada con las transacciones electrónicas será almacenada por Epayco. No nos responsabilizamos por las políticas o prácticas de privacidad de Epayco, ni tampoco de los archivos subidos que contienen la información de los usuarios. </p>

            <h2>SEXTA. TRATAMIENTO DE DATOS SENSIBLES Y DE MENORES DE EDAD</h2>
            <p>De acuerdo con la Ley 1581 de 2012, se entiende por datos sensibles aquellos que afectan la intimidad del titular de dichos datos, o cuyo uso indebido pueda generar su discriminación, tales como los que revelen origen racial o étnico; orientación política; convicciones religiosas o filosóficas; pertenencia a sindicatos, organizaciones sociales o de derechos humanos; los relativos a la salud, a la vida sexual y los datos biométricos.  Se informa a los UDUARIOS que no podrán en ningún evento a autorizar el tratamiento de datos sensibles y de menores de edad, y por lo tanto la prestación de nuestros servicios está condicionada a la restricción de esta información sensible.</p>


            <h2>SÉPTIMA. LEY APLICABLE Y SOLUCIÓN DE CONTROVERSIAS.</h2>
            <p>Los presentes Términos y Condiciones serán regidos e interpretados según la legislación colombiana vigente y aplicable para las distintas materias. </p>

            <p>Cualquier controversia que se presente entre las partes, directa o indirectamente relacionada con estos Términos y Condiciones de Uso, será resueltas mediante negociación directa entre las partes, en un término máximo de treinta (30) días calendario, contados desde la presentación formal y por escrito del planteamiento de las diferencias.</p>

            <p>Si vencido este término las partes no logran llegar a un acuerdo, convocarán a una audiencia de conciliación extrajudicial ante el Centro de Conciliación de la Cámara de Comercio de Sincelejo, y de no ser posible un acuerdo integral sobre las discrepancias, someterán sus diferencias al conocimiento de la Justicia Ordinaria de Colombia.</p>

            <h2>OCTAVA. ACEPTACIÓN TOTAL DE LOS TÉRMINOS Y CONDICIONES</h2>
            <p>El USUARIO manifiesta expresamente tener capacidad legal para usar la PLATAFORMA y para celebrar las transacciones comerciales que se puedan generar con sus ALIADOS COMERCIALES, USUARIOS O RECEPTORES DE LA INFORMACIÓN FINAL. Así mismo, manifiesta haber suministrado información real, veraz y fidedigna.</p>

            <p>Por ende, de forma expresa e inequívoca declara que ha leído, que entiende y que acepta la totalidad de las situaciones reguladas en el presente escrito de Términos y Condiciones de Uso de la PLATAFORMA, por lo que se compromete al cumplimiento total de los deberes, obligaciones, acciones y omisiones aquí expresadas.</p>

            <p>En caso de que USUARIOS de otros países utilicen la PLATAFORMA para solicitar productos y servicios en Colombia se sujetan completamente a lo dispuesto en los presentes términos.</p>
            <p>Con la aceptación de estos Términos y condiciones, el USUARIO manifiesta libre, expresa y previamente haber sido informado sobre los derechos que la ley le concede como titular de las bases de datos que suben en la PLATAFORMA de marketing digital y libera al OPERADOR de cualquier afectación con terceros. Debido a que el OPERADOR, no se involucra en las relaciones o tratos, información, mensajes, audios remitidos a sus usuarios, en el evento en que usted tenga una disputa con uno o más usuarios, usted libera al OPERADOR (y a sus empleados y agentes) de cualquier reclamación, demanda o daño de cualquier naturaleza, que surja de o de cualquier otra forma se relacione con dicha disputa.
            Son sus obligaciones una vez aceptados estos términos y condiciones:</p>
            <ul>
                <li>
                    Conocer, actualizar y rectificar sus bases de datos frente a la entidad responsable del tratamiento o encargada del tratamiento de sus datos personales.
                </li>
                <li>
                    Almacenar prueba de la autorización otorgada del tratamiento de bases de datos, de datos personales y del uso de estos.
                </li>
                <li>
                    Responder ante la Superintendencia de Industria y Comercio por quejas de infracciones al régimen de protección de datos personales y administración de bases de datos.
                </li>
                <li>
                    Revocar la autorización y/o solicitar la supresión del dato personal incluido en sus bases de datos en los términos de la Ley 1581 de 2012.
                </li>
                <li>
                    En caso de que, con ocasión de los contratos celebrados con terceros, el USUARIOS podrá agregar a estos contratos cláusulas que establezcan restricciones o requisitos sobre el manejo de esta información para el uso de la PLATAFORMA.
                </li>
            </ul>
           
           <br>
           <p>
                Favor comunicarse para resolver inquietudes con el área responsable de la supervisión de los presentes Términos y condiciones:
                <br>
                Email: soporte@falconsignal.co
           </p>



        </div>
    </v-container>
</template>

<script>
export default {
    mounted() {
        this.$store.state.menuAdminActive = true
    },
}
</script>